import React, {useState} from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';

const Merchant = () => {
    const [value, setValue] = useState('');
    const onChange = (event, {newValue}) => {
        console.log(`value: ${newValue}`);
        setValue(newValue);
    };
    const [suggestions, setSuggestions] = useState([]);

    const inputProps = {
        placeholder: 'Merchant search',
        value,
        onChange
    };

    const renderSuggestion = (suggestion) => {
        // console.log('rendering suggestion');
        // console.log(suggestion);
        return <div>{suggestion.name}</div>
    }

    const getSuggestionValue = suggestion => {
        // console.log('getSuggestionValue');
        return suggestion.name;
    }

    const onSuggestionsFetchRequested = ({value, reason}) => {
        // console.log('onSuggestionsFetchRequested');
        // console.log(value);
        axios.get(`https://x0yp1zzr49.execute-api.ap-southeast-1.amazonaws.com/dev/${value}`)
            .then(res => {
                const data = res.data;
                setSuggestions(data.merchants);
            })
    }
    const onSuggestionsClearRequested = () => {
        // console.log('onSuggestionsClearRequested');
        setSuggestions([]);
    }

    const onSuggestionSelected = (event, suggestion) => {
        console.log(`onSuggestionSelected: suggestion:`);
        console.log(suggestion);
    }
    return <div>
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            onSuggestionSelected={onSuggestionSelected}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={inputProps}
        />
    </div>;
}

export default Merchant;