import React from 'react';

const divStyle = {
    // color: 'white',
    // borderColor: 'yellow',
    // borderStyle: 'solid',
    // height: '200px',
    // width: '400px',
    // paddingBottom: '20px',
    // marginLeft: '50px'
    // marginLeft: '15%',
    // marginRight: '15%',
    marginBottom: '15%',
    width: '100%'
};

const  CreditCard = ({
    type, name, selected, onClick
                     }) => {
    return <div onClick={onClick} style={divStyle}>
     {type}<br/> {name}<br/>
    </div>;
}

export default CreditCard;