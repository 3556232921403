import './App.css';
import CreditCard from './CreditCard';
import Merchant from './Merchant';
import {useState} from "react";
import Carousel from 'react-bootstrap/Carousel';

const cards = [
    {
        id: 1,
        type: 'VISA',
        name: 'HSBC Revolution VISA'
    },
    {
        id: 2,
        type: 'Mastercard',
        name: 'SCB Unlimited Cashback'
    },
    {
        id: 3,
        type: 'VISA',
        name: 'DBS Live Fresh'
    },
]
const App = () => {
    const [selectedCard, setSelectedCard] = useState(2);


    const handleSelect = (selectedIndex, e) => {
        setSelectedCard(selectedIndex);
    };

    return (
        <div className="App">

            <Merchant/>
            <header className="App-header">
                <Carousel activeIndex={selectedCard} onSelect={handleSelect} interval={null}
                style={{width: '100%'}}>
                    {
                        cards.map((card) => {
                            const isSelected = selectedCard === card.id;
                            return (<Carousel.Item>
                                <CreditCard key={card.id} type={card.type} name={card.name}
                                            selected={isSelected}
                                />
                            </Carousel.Item>)
                        })
                    }
                </Carousel>
            </header>
        </div>
    );
}

export default App;
